import React, { useState, useEffect } from "react";
import axios from "axios";

const GitHubStats = () => {
  const [profileData, setProfileData] = useState(null);
  const [repos, setRepos] = useState([]);
  const [commits, setCommits] = useState(0); // Pour le nombre de commits
  const [privateRepos, setPrivateRepos] = useState(0); // Pour le nombre de projets privés
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRepos, setTotalRepos] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const reposPerPage = 9;
  const totalPages = Math.ceil(totalRepos / reposPerPage); // Calculer le nombre total de pages

  useEffect(() => {
    const fetchGitHubData = async () => {
      try {
        const profileResponse = await axios.get(
          "https://api.github.com/users/ludolpr"
        );

        const reposResponse = await axios.get(
          `https://api.github.com/user/repos?per_page=${reposPerPage}&page=${currentPage}&sort=created&direction=desc`,
          { headers: { Authorization: `Bearer ${process.env.TOKEN_PERSO}` } }
        );

        // Total des dépôts privés (fournis dans le profil utilisateur)
        setPrivateRepos(profileResponse.data.total_private_repos || 0);

        // Calculate total commits
        let totalCommits = 0;
        await Promise.all(
          reposResponse.data.map(async (repo) => {
            const commitResponse = await axios.get(`${repo.url}/commits`, {
              headers: { Authorization: `Bearer ${process.env.TOKEN_PERSO}` },
            });
            totalCommits += commitResponse.data.length;
          })
        );

        setTotalRepos(
          parseInt(reposResponse.headers["x-total-count"], 10) ||
            reposResponse.data.length
        );
        setCommits(totalCommits);
        setProfileData(profileResponse.data);
        setRepos(reposResponse.data);
        setLoading(false);
      } catch (err) {
        console.error(
          "Erreur lors de la récupération des données GitHub:",
          err
        );
        setError("Erreur lors de la récupération des données GitHub.");
        setLoading(false);
      }
    };

    fetchGitHubData();
  }, [currentPage]);
  

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(totalRepos / reposPerPage)) {
      setCurrentPage(newPage);
    }
  };

  const getPaginationRange = () => {
    const totalPages = Math.ceil(totalRepos / reposPerPage);
    const range = [];
    const maxPagesToShow = 5;

    let start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let end = Math.min(
      totalPages,
      currentPage + Math.floor(maxPagesToShow / 2)
    );

    if (end - start < maxPagesToShow - 1) {
      if (currentPage <= 3) {
        end = Math.min(maxPagesToShow, totalPages);
      } else if (currentPage >= totalPages - 2) {
        start = Math.max(1, totalPages - maxPagesToShow + 1);
      }
    }

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  };

  if (loading)
    return (
      <div className="text-center text-xl text-[#f5f5f5]">Chargement...</div>
    );
  if (error)
    return <div className="text-center text-xl text-red-500">{error}</div>;

  return (
    <div className="w-full min-h-screen bg-[#44427d] py-16 text-white">
      <div className="max-w-screen-xl mx-auto px-8 text-center md:text-left mt-10">
        <h2 className="text-5xl md:text-7xl tracking-wider uppercase text-[#e1927f] font-bold mb-6">
          GitHub
        </h2>
        {profileData && (
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <div className="flex items-center">
              <img
                src={profileData.avatar_url}
                alt={`${profileData.login}'s avatar`}
                className="w-20 h-20 rounded-full mr-6"
              />
              <div>
                <h3 className="text-2xl text-[#44427d] font-bold">
                  {profileData.name}
                </h3>
                <p className="text-[#44427d]">{profileData.bio}</p>
                {profileData.location && (
                  <p className="text-[#44427d]">
                    <strong>Location:</strong> {profileData.location}
                  </p>
                )}
                {profileData.company && (
                  <p className="text-[#44427d]">
                    <strong>Company:</strong> {profileData.company}
                  </p>
                )}
                <p className="text-[#44427d]">
                  Public Repos: {profileData.public_repos}
                </p>
                <p className="text-[#44427d]">Private Repos: {privateRepos}</p>

                <p className="text-[#44427d]">Total Commits: {commits}</p>
                <p className="text-[#44427d]">
                  Followers: {profileData.followers}
                </p>
                <p className="text-[#44427d]">
                  Following: {profileData.following}
                </p>
                <a
                  href={profileData.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#e1927f] underline"
                >
                  Voir le profil GitHub
                </a>
              </div>
            </div>
          </div>
        )}

        <h1 className="text-5xl md:text-7xl tracking-wider uppercase text-[#e1927f] font-bold mb-6 text-center m-2">
          Statistiques
        </h1>
        <div className="flex justify-center flex-wrap mb-8">
          <a
            href="https://github.com/vn7n24fzkq/github-profile-summary-cards"
            className="m-2"
          >
            <img
              src="https://raw.githubusercontent.com/ludolpr/ludolpr72/master/profile-summary-card-output/nord_bright/1-repos-per-language.svg"
              alt="Repos per language"
              className="rounded-lg shadow-md  m-2"
            />
          </a>
          <a
            href="https://github.com/vn7n24fzkq/github-profile-summary-cards"
            className="m-2"
          >
            <img
              src="https://raw.githubusercontent.com/ludolpr/ludolpr72/master/profile-summary-card-output/nord_bright/2-most-commit-language.svg"
              alt="Most commit language"
              className="rounded-lg shadow-md m-2"
            />
          </a>
          <a
            href="https://github.com/vn7n24fzkq/github-profile-summary-cards"
            className="m-2"
          >
            <img
              src="https://raw.githubusercontent.com/ludolpr/ludolpr72/master/profile-summary-card-output/nord_bright/3-stats.svg"
              alt="Stats"
              className="rounded-lg shadow-md m-2"
            />
          </a>
        </div>
        <div className="bg-[#44427d] rounded-lg shadow-md p-6">
          <h3 className="text-xl font-bold text-[#e1927f]">
            Suivi des Projets
          </h3>
          <div className="grid grid-cols-3 gap-4 mt-4">
            {repos.slice(0, 1).map((repo) => (
              <div
                key={repo.id}
                className="bg-white p-4 rounded-lg shadow-md mt-4 mb-4"
              >
                <h4 className="text-lg font-semibold text-[#44427d]">
                  {repo.name}
                </h4>
                <p className="text-[#44427d]">{repo.description}</p>
                <a
                  href={repo.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#e1927f] underline"
                >
                  Voir le repo
                </a>
              </div>
            ))}
          </div>
        </div>

        <h3 className="text-4xl tracking-wider uppercase text-[#e1927f] font-bold mb-6">
          Derniers Répertoires
        </h3>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {repos.map((repo) => (
            <div key={repo.id} className="bg-white rounded-lg shadow-md p-6">
              <h4 className="text-2xl text-[#44427d] font-bold">{repo.name}</h4>
              <p className="text-[#44427d]">{repo.description}</p>
              <p className="text-[#44427d]">
                <strong>Language:</strong> {repo.language}
              </p>
              <p className="text-[#44427d]">
                <strong>Stars:</strong> {repo.stargazers_count}
              </p>
              <p className="text-[#44427d]">
                <strong>Forks:</strong> {repo.forks_count}
              </p>
              <p className="text-[#44427d]">
                <strong>Commits:</strong> {repo.commits}
              </p>
              <a
                href={repo.html_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#e1927f] underline"
              >
                Voir le repo
              </a>
            </div>
          ))}
        </div>

        <div className="mt-8 flex justify-center items-center space-x-3">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 rounded bg-[#e1927f] text-white disabled:bg-gray-300"
          >
            Précédent
          </button>

          {getPaginationRange().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-4 py-2 mx-1 rounded ${
                page === currentPage
                  ? "bg-[#e1927f] text-white"
                  : "bg-gray-200 text-[#44427d]"
              }`}
            >
              {page}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 rounded bg-[#e1927f] text-white disabled:bg-gray-300"
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default GitHubStats;
